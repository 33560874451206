<template>
  <div class="row">
    <div class="col-md-12">
      <div class="box" v-if="!form.date">
        <div class="box-body p-3 h-500">
          <div id="visit-date-calendar"></div>
        </div>
      </div>

      <div class="box" v-else>
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-6">
              <h4 class="box-title">{{ formatDate(form.date) }} / {{ patientName() }}</h4>
            </div>
            <div class="col-6 text-end">
              <button class="btn btn-sm btn-primary"
                      @click="$emit('nextStep')">+ Add New</button>
            </div>
          </div>
        </div>

        <visit-list :visits="visits"
                    :formData="form"
                    @nextStep="$emit('nextStep')"
                    v-if="visits.length" />
        <no-visit v-else
                  @nextStep="$emit('nextStep')"
                  :formData="form" />
      </div>
    </div>
  </div>
</template>

<script>
import NoVisit from './NoVisit';
import VisitList from './VisitList';
import moment from 'moment'

export default {
  components: {NoVisit, VisitList},
  props: ['formData', 'errors', 'currentPatient'],
  data() {
    return {
      visits: [],
      form: {},
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  mounted() {
    this.initCalender();
  },
  methods: {
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD MMMM YYYY');
    },
    patientName() {
      return this.currentPatient.first_name + ' ' + this.currentPatient.last_name;
    },
    loadVisits() {
      this.visits = [];

      if (this.form.patient_type === '1') {
        this.axios.get('/visits?patient_id=' + this.form.selected_patient_id + '&date=' + this.form.date)
          .then(res => {
            this.visits = res.data.data;
          })
      }
    },
    initCalender() {
      let self = this;

      let calendarEl = document.getElementById('visit-date-calendar');
      let calendar = new window.FullCalendar.Calendar(calendarEl, {
        initialView: 'dayGridMonth',
        selectable: true,
        height: '100%',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth'
        },
        themeSystem: 'bootstrap5',
        dateClick: function(info) {
          self.form.date = info.dateStr
          self.loadVisits();
        },
      });
      calendar.render();
    }
  }
}
</script>