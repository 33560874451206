<template>
  <div class="row" v-if="form">
    <div class="col-12 h-500">
      <div class="box h-p100">
        <div class="box-header small-box-header">
          <h4 class="box-title">Selected {{ form.services.length }} service</h4>
        </div>
        <div class="box-body p-3">
          <ul class="list-group">
            <li class="list-group-item"
                v-for="service in services"
                :key="'service_'+service.id">
              <div class="form-group mb-0">
                <div class="checkbox checkbox-success">
                  <input :id="'service_'+service.id"
                         v-model="service_ids"
                         :value="service.id" type="checkbox">
                  <label :for="'service_'+service.id"> {{ service.name_th}}</label>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData'],
  data() {
    return {
      service_ids: [],
      services: [],
      form: {},
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    service_ids(ids) {
      this.form.services = this.services.filter(s => ids.includes(s.id));
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  created() {
    this.loadServices()
  },
  methods: {
    loadServices() {
      this.axios.get('/services?status=1')
        .then(res => {
          this.services = res.data.data;
          this.service_ids = this.form.services.map(s => s.id);
        })
    }
  }
}
</script>