<template>
  <div class="modal fade bs-example-modal-lg"
       id="new-visit-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg modal-xl modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">Add New Visit List</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body min-h-500">
          <div class="row mb-2">
            <div class="col-12">
              <button :class="'btn btn-sm me-2 ' + (currentStep === 1 ? 'btn-primary' : 'btn-secondary')">1. ประเภทลูกค้า</button>
              <button :class="'btn btn-sm me-2 ' + (currentStep === 2 ? 'btn-primary' : 'btn-secondary')">2. เลือกวันที่</button>
              <button :class="'btn btn-sm me-2 ' + (currentStep === 3 ? 'btn-primary' : 'btn-secondary')">3. จัดการเคส</button>
              <button :class="'btn btn-sm ' + (currentStep === 4 ? 'btn-primary' : 'btn-secondary')">4. ยืนยันข้อมูล</button>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
                <component v-bind:is="currentComponent"
                           :currentPatient="currentPatient"
                           :errors="allErrors"
                           @nextStep="nextStep"
                           :formData="form" />
            </div>
          </div>
        </div>
        <div class="modal-footer p-2">
          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary btn-sm" data-bs-dismiss="modal">ยกเลิกการสร้าง</button>
            </div>
            <div class="col-md-6 text-end">
              <button type="button"
                      @click="prevStep"
                      :disabled="currentStep === 1"
                      :class="['btn me-3 btn-sm', {'btn-info-light': currentStep === 1}, {'btn-primary': currentStep !== 1}]">
                <i class="fas fa-arrow-left"></i> ย้อนกลับ
              </button>
              <button type="button"
                      @click="nextStep"
                      v-if="currentStep !== 4"
                      :disabled="!canGoNext"
                      :class="['btn btn-sm', {'btn-info-light': currentStep === 4}, {'btn-primary': currentStep !== 4}]">
                ถัดไป <i class="fas fa-arrow-right"></i>
              </button>
              <button type="button"
                      v-else
                      @click="submit"
                      :disabled="loading"
                      class="btn btn-primary btn-sm">
                สร้าง Visit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import StepOne from './steps/1/StepOne';
import StepTwo from './steps/2/StepTwo';
import StepThree from './steps/3/StepThree';
import StepFour from './steps/4/StepFour';
import StepFive from './steps/5/StepFive';
import useVuelidate from "@vuelidate/core";
import {required, minLength} from "@vuelidate/validators";

export default {
  components: {StepOne, StepTwo, StepThree, StepFour, StepFive},
  data() {
    return {
      currentStep: 1,
      modal: null,
      form: {},
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    if (this.currentStep === 1) {
      if (this.form.patient_type === '1') {
        return {
          form: {
            selected_patient_id: {required},
          },
        }
      } else {
        return {
          form: {
            patient: {
              name_prefix_id: {required},
              first_name: {required},
              last_name: {required},
              mobile: {required},
              blood_group_id: {required},
              congenital_disease: {required},
              drug_allergy: {required},
              dob: {required},
            }
          }
        }
      }
    } else if (this.currentStep === 2) {
      return {
        form: {
          date: {required},
          visit_id: {required},
        },
      }
    } else if (this.currentStep === 3) {
      return {
        form: {
          cases: { required, minLength: minLength(1), },
        },
      }
    }
  },
  computed: {
    currentPatient() {
      if (this.form.patient_type === '1')
        return this.form.currentPatient;
      else
        return this.form.patient;
    },
    currentComponent() {
      if (this.currentStep === 1)
        return 'step-one';
      else if  (this.currentStep === 2)
        return 'step-two';
      else if  (this.currentStep === 3)
        return 'step-three';
      else if  (this.currentStep === 4)
        return 'step-four';
      else
        return 'step-five';
    },
    canGoNext() {
      if (this.currentStep === 1) {
        return !this.v$.form.$invalid
      } else if (this.currentStep === 2) {
        return !this.v$.form.$invalid
      } else if (this.currentStep === 3) {
        return !this.v$.form.$invalid
      } else
        return true;
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('new-visit-modal'));
  },
  methods: {
    resetForm() {
      this.currentStep = 1;
      this.form = {
        currentPatient: null,
        visit_id: '',
        date: '',
        patient_type: null,
        selected_patient_id: '',
        cases: [],
        patient: {
          image: '',
          name_prefix_id: '',
          nick_name: '',
          first_name: '',
          last_name: '',
          dob: '',
          gender: '',
          country_id: '',
          nationality: '',
          id_card_number: '',
          passport_number: '',
          occupation: '',
          occupation_other: '',
          mobile: '',
          mobile_alternative: '',
          email: '',
          blood_group_id: '',
          congenital_disease: '',
          drug_allergy: '',
          note: '',
          status: '1',
          address: {
            country_id: '',
            house_no: '',
            village: '',
            group: '',
            alley: '',
            road: '',
            province: '',
            district: '',
            sub_district: '',
            zip_code: '',
            phone: '',
          },
          emergencyContact: {
            show: false,
            name_prefix_id: '',
            name: '',
            relationship_id: '',
            relationship_other: '',
            mobile_country_code: '',
            mobile: '',
            mobile_alternative: '',
            email: '',
            country_id: '',
            house_no: '',
            village: '',
            group: '',
            alley: '',
            road: '',
            province: '',
            district: '',
            sub_district: '',
            zip_code: '',
            phone: '',
          },
        }
      };
    },
    show() {
      this.resetForm();
      this.modal.show();
    },
    nextStep() {
      if (this.currentStep === 1) {
        this.form.date = '';
        this.form.visit_id = '';
      }

      this.currentStep++;
    },
    prevStep() {
      if (this.currentStep === 3) {
        this.form.date = '';
        this.form.visit_id = '';
      }

      this.currentStep--;
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let url = '/visits';

      this.axios.post(url, this.form)
        .then(() => {
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>