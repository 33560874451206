<template>
  <div class="row">
    <div class="col-6 col-lg-3" v-for="doctor in doctors"
         @click="form.doctor = doctor"
         :key="'doctor_'+doctor.id">
      <div class="box ribbon-box">
        <div class="ribbon-two ribbon-two-success"
             v-if="form.doctor && form.doctor.id === doctor.id"><span>Selected</span></div>
        <div class="box-body text-center">
          <img :src="doctor.image ? doctor.image : '/images/avatar-girl.png'"
               onerror="this.src='/images/avatar-girl.png'"
               class="h-150">
          <div class="text-center">
            <h3 class="my-10">
              <a href="javascript:void(0)">{{ doctor.full_name }}</a>
            </h3>
            <h6 class="user-info mt-0 mb-10 text-fade">{{ doctor.department.name }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData'],
  data() {
    return {
      doctors: [],
      form: {},
    }
  },
  created() {
    this.axios.get('/doctors').then(res => {
      this.doctors = res.data.data;
    })
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
}
</script>