<template>
  <div class="box-body p-3">
    <ul class="list-group">
      <li v-for="visit in visits"
          :key="'visit_'+visit.id"
          class="list-group-item bg-primary-light d-flex justify-content-between align-items-center">
        {{ visit.visit_no }} / {{ visit.cases.length }} cases

        <button class="btn btn-primary btn-sm"
                @click="selectVisit(visit)">Select</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['visits', 'formData'],
  data() {
    return {
      form: {}
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    selectVisit(visit) {
      this.form.visit_id = visit.id;
      this.$emit('nextStep')
    }
  }
}
</script>