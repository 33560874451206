<template>
  <div class="box-body p-3 h-500  d-flex align-items-center">
    <div class="text-center w-p100">
      <i class="fas fa-clipboard fs-100 text-muted"></i>
      <h4 class="mt-3">No case added yet</h4>
      <button class="btn btn-sm btn-primary"
              @click="$refs.addCaseModal.show(form.date, false, 1)">+ Add Case</button>
    </div>
  </div>

  <add-case-modal :id="2"
                  :formData="form"
                  ref="addCaseModal"
                  @addNewCase="addNewCase" />
</template>

<script>
import AddCaseModal from "@/views/appointment/newVisitModal/steps/3/AddCaseModal";
export default {
  components: {AddCaseModal},
  props: ['formData'],
  data() {
    return {
      form: {},
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    addNewCase(newCase) {
      this.form.cases.push(newCase)
    }
  }
}
</script>