<template>
  <div class="content-header pb-0">
    <div class="row">
      <div class="col-6">
        <h3 class="page-title">Visit Details</h3>
      </div>
      <div class="col-6 text-end" v-if="visit">
        <select v-if="editingStatus"
                v-model="statusForm.status"
                class="form-select form-select-sm d-inline w-auto">
          <option value="1">Draft</option>
          <option value="2">Open</option>
          <option value="3">Ongoing</option>
          <option value="4">Finished</option>
          <option value="5">Cancel</option>
        </select>

        <appointment-status :status="visit.status" v-else />

        <button class="btn btn-sm btn-secondary ms-2"
                v-if="!editingStatus"
                @click="editStatus">
          <i class="fas fa-edit"></i>
        </button>

        <button class="btn btn-sm btn-success ms-2"
                v-else
                @click="saveStatus">
          <i class="fas fa-save"></i>
        </button>
      </div>
    </div>
  </div>

  <hr>

  <div class="row" v-if="visit">
    <div class="col-xl-4 col-12">
      <div class="box">
        <div class="box-header small-box-header with-border">
          <div class="row d-flex align-items-center">
            <div class="col">
              <h4 class="box-title">Profile</h4>
            </div>
            <div class="col text-end">
              <router-link :to="{name: 'patients_details', params: {id: visit.patient.id}}"
                           class="btn-sm waves-effect waves-light btn btn-info-light">View Profile</router-link>
            </div>
          </div>
        </div>
        <div class="box-body">
          <table class="patient-details-table table table-borderless table-sm">
            <tr>
              <td><span style="color: #5156BE">◆</span> H/N</td>
              <td>: {{ visit.patient.hn }}</td>
            </tr>
            <tr>
              <td><span style="color: #5156BE">◆</span> Name</td>
              <td>: {{ visit.patient.full_name }}</td>
            </tr>
            <tr>
              <td><span style="color: #5156BE">◆</span> Blood Type</td>
              <td>: {{ visit.patient.bloodGroup.name }}</td>
            </tr>
            <tr>
              <td><span style="color: #5156BE">◆</span> Medical Allergies</td>
              <td>: {{ visit.patient.drug_allergy }}</td>
            </tr>
            <tr>
              <td><span style="color: #5156BE">◆</span> Congenital Disease</td>
              <td>: {{ visit.patient.congenital_disease }}</td>
            </tr>
            <tr>
              <td><span style="color: #5156BE">◆</span> Visit Date</td>
              <td>: </td>
            </tr>
            <tr>
              <td><span style="color: #5156BE">◆</span> Visit Time</td>
              <td>: </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="box">
        <div class="box-header small-box-header with-border">
          <div class="row d-flex align-items-center">
            <div class="col">
              <h4 class="box-title">Vitals</h4>
            </div>
            <div class="col text-end">
              <button @click="$refs.vitalEditModal.show()"
                      class="btn-sm waves-effect waves-light btn btn-info-light">
                <i class="fas fa-edit"></i> Edit Info
              </button>
            </div>
          </div>
        </div>
        <div class="box-body">
          <table class="patient-details-table table table-borderless table-sm">
            <tr>
              <td>
                <span style="color: #5156BE">◆</span> Body Temperature
              </td>
              <td>: {{ visit.vitals ? visit.vitals.temperature : '' }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: #5156BE">◆</span> Pulse Rate
              </td>
              <td>: {{ visit.vitals ? visit.vitals.pulse : '' }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: #5156BE">◆</span> Respiration Rate
              </td>
              <td>: {{ visit.vitals ? visit.vitals.respiration : '' }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: #5156BE">◆</span> Blood Pressure
              </td>
              <td>: {{ visit.vitals ? visit.vitals.blood_pressure : '' }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: #5156BE">◆</span> Sp O2
              </td>
              <td>: {{ visit.vitals ? visit.vitals.sp_o2 : '' }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: #5156BE">◆</span> Height
              </td>
              <td>: {{ visit.vitals ? visit.vitals.height : '' }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: #5156BE">◆</span> Weight
              </td>
              <td>: {{ visit.vitals ? visit.vitals.weight : '' }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="box">
        <div class="box-header small-box-header with-border">
          <div class="row d-flex align-items-center">
            <div class="col">
              <h4 class="box-title">Portrait</h4>
            </div>
            <div class="col text-end">
              <button class="btn-sm me-2 waves-effect waves-light btn btn-info-light"
                      @click="$refs.portraitInput.click()">
                <i class="fas fa-upload"></i> อัปโหลด
              </button>
              <button class="btn-sm waves-effect waves-light btn btn-info-light">
                <i class="fas fa-camera"></i> ถ่ายภาพ
              </button>
            </div>
          </div>
        </div>
        <div class="box-body">
          <div class="row">
            <div class="col-6"
                 v-for="portrait in visit.portraits"
                 :key="'portrait_'+portrait.id">
              <img class="rounded max-h-200 mb-3" :src="portrait.image">
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-header small-box-header with-border">
          <div class="row d-flex align-items-center">
            <div class="col">
              <h4 class="box-title">Consent</h4>
            </div>
            <div class="col text-end">
              <div class="btn-group">
                <button class="btn-sm waves-effect waves-light btn btn-info-light dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown">Add New</button>
                <div class="dropdown-menu dropdown-menu-end">
                  <a class="dropdown-item"
                     href="#"
                     @click.prevent="$refs.consentInput.click()"><i class="fas fa-upload"></i> Upload</a>
                  <a class="dropdown-item" href="#"><i class="fas fa-file"></i> Select From Template</a>
                  <a class="dropdown-item" href="#"><i class="fas fa-video"></i> Record Video</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-body">
          <div class="d-flex flex-column">
            <div class="p-2 rounded bg-info-light mb-2"
                 v-for="consent in visit.consents"
                 :key="'consent_'+consent.id">

              <a target="_blank" :href="consent.file">
                <i class="fas fa-file"></i> {{ consent.original_filename }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-12">
      <div class="row mb-3">
        <div class="col-12">
          <div class="col-6">
            <router-link :class="['waves-effect btn-sm waves-light btn btn-primary me-10', {'btn-outline': $route.name !== 'visit_details_cases'}]"
                         :to="{name: 'visit_details_cases', params: {id: $route.params.id}}">Cases</router-link>
            <router-link :class="['waves-effect btn-sm waves-light btn btn-primary me-10', {'btn-outline': $route.name !== 'visit_details_prescriptions'}]"
                         :to="{name: 'visit_details_prescriptions', params: {id: $route.params.id}}">Prescription</router-link>
            <router-link :class="['waves-effect btn-sm waves-light btn btn-primary me-10', {'btn-outline': $route.name !== 'visit_details_documents'}]"
                         :to="{name: 'visit_details_documents', params: {id: $route.params.id}}">Documents</router-link>
          </div>
        </div>
      </div>

      <router-view />
    </div>
  </div>

  <visit-details-vital-edit-modal v-if="visit"
                                  @vitalUpdated="vitalUpdated"
                                  :visit="visit"
                                  ref="vitalEditModal" />

  <consent-details-modal ref="consentDetailsModal" />

  <input @change="readPortraitURL($event.target)"
         type="file"
         accept="image/*"
         ref="portraitInput" style="display: none">

  <input @change="readConsentURL($event.target)"
         type="file"
         ref="consentInput" style="display: none">
</template>

<script>
import VisitDetailsVitalEditModal from "@/views/appointment/VisitDetails/VisitDetailsVitalEditModal";
import ConsentDetailsModal from "@/views/appointment/VisitDetails/ConsentDetailsModal";
import AppointmentStatus from "@/views/appointment/AppointmentStatus.vue";
import {mapGetters} from "vuex";

export default {
  components: {VisitDetailsVitalEditModal, ConsentDetailsModal, AppointmentStatus},
  data() {
    return {
      editingStatus: false,
      statusForm: {
        status: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      visit: 'visitModule/getVisitObj'
    })
  },
  created() {
    this.loadVisit();
  },
  methods: {
    vitalUpdated(vitals) {
      this.visit.vitals = vitals
    },
    loadVisit() {
      this.$store.commit('visitModule/setVisitObj', null);

      this.axios.get('/visits/' + this.$route.params.id)
        .then(res => {
          this.$store.commit('visitModule/setVisitObj', res.data.data);
        })
    },
    editStatus() {
      this.statusForm.status = this.visit.status;
      this.editingStatus = true;
    },
    saveStatus() {
      this.statusForm.visit_id = this.visit.id;

      this.axios.post('/visits/change-status', this.statusForm)
        .then(() => {
          this.visit.status = this.statusForm.status;
          this.editingStatus = false;
        })
    },
    readPortraitURL(input) {
      let self = this;

      if (input.files && input.files[0]) {
        let reader = new FileReader();

        reader.onload = function (e) {
          self.axios.post('/visits/portrait', {
              visit_id: self.visit.id,
              patient_id: self.visit.patient.id,
              image: e.target.result
            })
            .then(res => {
              self.visit.portraits = res.data.data;
            });
        }

        reader.readAsDataURL(input.files[0]);
      }
    },
    readConsentURL(input) {
      if (input.files && input.files[0]) {
        let formData = new FormData();

        formData.append('file', input.files[0]);
        formData.append('visit_id', this.visit.id);
        formData.append('patient_id', this.visit.patient.id);
        formData.append('original_filename',  input.files[0].name);

        this.axios.post('/visits/consent', formData)
          .then((res) => {
            this.visit.consents = res.data.data;
          });
      }
    }
  }
}
</script>