<template>
  <div class="row">
    <div class="col-md-12">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-6">
              <h4 class="box-title">เลือการบริการเพื่อจัดเคส</h4>
            </div>
            <div class="col-6 text-end">
              <button class="btn btn-sm btn-primary"
                      v-if="form.cases.length"
                      @click="$refs.addCaseModal.show(form.date, false, form.cases.length + 1)">+ Add Case</button>
            </div>
          </div>
        </div>


        <no-case v-if="!form.cases.length" :formData="form" />
        <case-list v-else :formData="form" />
      </div>
    </div>
  </div>

  <add-case-modal ref="addCaseModal"
                  :formData="form"
                  :id="1"
                  @addNewCase="addNewCase" />
</template>

<script>
import NoCase from "@/views/appointment/newVisitModal/steps/3/NoCase";
import CaseList from "@/views/appointment/newVisitModal/steps/3/CaseList";
import AddCaseModal from "@/views/appointment/newVisitModal/steps/3/AddCaseModal";
export default {
  components: {NoCase, CaseList, AddCaseModal},
  props: ['formData', 'errors'],
  data() {
    return {
      form: {},
      doctors: [],
      doctorSlots: [],
      services: []
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  mounted() {
    if (this.form.visit_id) {
      this.loadCases(this.form.visit_id)
    }
  },
  methods: {
    loadCases(visitId) {
      this.axios.get('/visit-cases?visit_id=' + visitId)
        .then(res => {
          let cases = [];

          res.data.data.forEach(c => {
            c.slot = c.doctorSlot;
            c.date = this.form.date;

            cases.push(c);
          })

          this.form.cases = cases;
        })
    },
    addNewCase(newCase) {
      if ('id' in newCase) {
        let i = this.form.cases.findIndex(c => c.id == newCase.id);

        if (i === -1)
          this.form.cases.push(newCase)
        else
          this.form.cases[i] = newCase
      } else {
        this.form.cases.push(newCase)
      }
    },
  }
}
</script>