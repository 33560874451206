<template>
  <div class="modal fade bs-example-modal-lg"
       id="vital-edit-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Edit Vital Information <br>
            <small class="text-muted" v-if="visit.vitals"> Modified Date: {{ visit.vitals.updated_at }}  |  Modified by: {{ visit.vitals.updatedBy ? visit.vitals.updatedBy.full_name : '' }}</small>
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('temperature')}]">
            <label class="col-sm-4 col-form-label">Body Temperature</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.temperature"
                     type="text">

              <v-errors :errors="errorFor('temperature')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('pulse')}]">
            <label class="col-sm-4 col-form-label">Pulse Rate</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.pulse"
                     type="text">

              <v-errors :errors="errorFor('pulse')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('respiration')}]">
            <label class="col-sm-4 col-form-label">Respiration Rate</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.respiration"
                     type="text">

              <v-errors :errors="errorFor('respiration')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('blood_pressure')}]">
            <label class="col-sm-4 col-form-label">Blood Pressure</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.blood_pressure"
                     type="text">

              <v-errors :errors="errorFor('blood_pressure')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('sp_o2')}]">
            <label class="col-sm-4 col-form-label">Sp O2</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.sp_o2"
                     type="text">

              <v-errors :errors="errorFor('sp_o2')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('height')}]">
            <label class="col-sm-4 col-form-label">Height</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.height"
                     type="text">

              <v-errors :errors="errorFor('height')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('weight')}]">
            <label class="col-sm-4 col-form-label">Weight</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.weight"
                     type="text">

              <v-errors :errors="errorFor('weight')"></v-errors>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  data-bs-dismiss="modal"
                  class="btn btn-danger me-3">ปิด</button>
          <button type="button"
                  :disabled="loading"
                  @click="submit"
                  class="btn btn-success">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  props: ['visit'],
  data() {
    return {
      modal: null,
      form: {
        visit_id: '',
        patient_id: '',
        temperature: '',
        pulse: '',
        respiration: '',
        blood_pressure: '',
        sp_o2: '',
        height: '',
        weight: '',
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('vital-edit-modal'));
  },
  methods: {
    show() {
      this.form.visit_id = this.visit.id;
      this.form.patient_id = this.visit.patient.id;
      this.form.temperature = this.visit.vitals ? this.visit.vitals.temperature : '',
      this.form.pulse = this.visit.vitals ? this.visit.vitals.pulse : '',
      this.form.respiration = this.visit.vitals ? this.visit.vitals.respiration : '',
      this.form.blood_pressure = this.visit.vitals ? this.visit.vitals.blood_pressure : '',
      this.form.sp_o2 = this.visit.vitals ? this.visit.vitals.sp_o2 : ''
      this.form.weight = this.visit.vitals ? this.visit.vitals.weight : ''
      this.form.height = this.visit.vitals ? this.visit.vitals.height : ''

      this.modal.show();
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/visits/vitals', this.form)
        .then((res) => {
          this.$emit('vitalUpdated', res.data.data);
          this.modal.hide();
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>