<template>
  <div class="box">
    <div class="box-body px-0 pb-0">
      <div class="px-20 bb-1 pb-15 d-flex align-items-center justify-content-between">
        <h4 class="mb-0">รายการเอกสาร</h4>
        <div class="d-flex align-items-center justify-content-end">
          <button type="button"
                  @click="$refs.documentInput.click()"
                  class="waves-effect waves-light btn btn-sm btn-info">
            <i class="fas fa-upload"></i> อัปโหลด
          </button>
        </div>
      </div>
    </div>
    <div class="box-body">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>ชื่อไฟล์</th>
          <th class="text-end">Action</th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="document in documents"
            :key="'document_'+document.id">
          <td>{{ document.original_filename }}</td>
          <td class="text-end">
            <a class="text-muted"
               target="_blank"
               :href="document.file">
              <i class="fas fa-file"></i> View
            </a>
            |
            <a class="text-muted"
               @click.prevent="download(document.file, document.original_filename)"
               href="#">
              <i class="fas fa-download"></i> Download
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <input @change="readDocumentURL($event.target)"
         type="file"
         ref="documentInput" style="display: none">
</template>

<script>
import {mapGetters} from "vuex";
// import { downloadFile } from 'fs-browsers';

export default {
  data() {
    return {
      documents: []
    }
  },
  computed: {
    ...mapGetters({
      visit: 'visitModule/getVisitObj'
    })
  },
  created() {
    this.loadDocuments();
  },
  methods: {
    loadDocuments() {
      this.documents = [];

      this.axios.get('/visits/documents?visit_id=' + this.$route.params.id)
        .then(res => {
          this.documents = res.data.data;
        })
    },
    readDocumentURL(input) {
      if (input.files && input.files[0]) {
        let formData = new FormData();

        formData.append('file', input.files[0]);
        formData.append('visit_id', this.visit.id);
        formData.append('patient_id', this.visit.patient.id);
        formData.append('original_filename',  input.files[0].name);

        this.axios.post('/visits/document', formData)
          .then((res) => {
            this.documents = res.data.data;
          });
      }
    }
  }
}
</script>