<template>
  <div class="row" v-if="form.patient_type === '2' || patient">
    <div class="col-md-4">
      <div class="box h-p100 mb-0">
        <div class="box-header small-box-header">
          <h4 class="mb-0">ข้อมูลลูกค้า</h4>
        </div>
        <div class="box-body text-center p-3">
          <img v-if="form.patient_type === '1'"
               :src="patient.image ? patient.image : '/images/avatar-girl.png'"
               onerror="this.src='/images/avatar-girl.png'"
               class="h-100">

          <img v-else
               :src="form.patient.image ? form.patient.image : '/images/avatar-girl.png'"
               onerror="this.src='/images/avatar-girl.png'"
               class="h-100">

          <table class="table table-borderless patient-details-table text-start mt-3">
            <tr v-if="form.patient_type === '1'">
              <td>รหัสลูกค้า</td>
              <td>: {{ patient.patient_id }}</td>
            </tr>
            <tr v-if="form.patient_type === '1'">
              <td>HN</td>
              <td>: {{ patient.hn }}</td>
            </tr>
            <tr>
              <td>การใช้งาน</td>
              <td class="form-group" v-if="form.patient_type === '1'">
                <div class="c-inputs-stacked">
                  :
                  <input name="group123" type="radio" id="radio_123" value="1" disabled :checked="patient.status === 1">
                  <label for="radio_123" class="me-10">เปิด</label>
                  <input name="group123" type="radio" id="radio_456" value="0" disabled :checked="patient.status === 0">
                  <label for="radio_456" class="me-10">ปิด</label>
                </div>
              </td>
              <td class="form-group" v-else>
                <div class="c-inputs-stacked">
                  :
                  <input name="patient_status"
                         type="radio"
                         v-model="form.patient.status"
                         disabled
                         id="patient_active" value="1">
                  <label for="patient_active" class="me-30">เปิด</label>
                  <input name="patient_status"
                         type="radio"
                         disabled
                         v-model="form.patient.status"
                         id="patient_inactive" value="0">
                  <label for="patient_inactive" class="me-30">ปิด</label>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="box h-p100 mb-0">
        <div class="box-header small-box-header">
          <h4 class="mb-0">ข้อมูลส่วนตัว</h4>
        </div>
        <div class="box-body p-3">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group form-group-sm mb-0 row">
                <label class="col-sm-4 col-form-label">ชื่อลูกค้า</label>
                <div class="col-sm-8">
                  <input class="form-control form-control-sm"
                         type="text"
                         :value="form.patient_type === '1' ? patient.full_name : (form.first_name + ' ' + form.last_name)" disabled>
                </div>
              </div>
              <div class="form-group form-group-sm mb-0 row">
                <label class="col-sm-4 col-form-label">ชื่อเล่น</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm"
                         type="text"
                         :value="form.patient_type === '1' ? patient.nick_name : (form.nick_name + ' ' + form.nick_name)" disabled>
                </div>
              </div>
              <div class="form-group form-group-sm mb-0 row">
                <label class="col-sm-4 col-form-label">เพศ</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm"
                         type="text"
                         :value="form.patient_type === '1' ? patient.gender : form.gender" disabled>
                </div>
              </div>
              <div class="form-group form-group-sm mb-0 row">
                <label class="col-sm-4 col-form-label">วันเกิด</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm"
                         type="text"
                         value="23 มีนาคม 1993" disabled>
                </div>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm"
                         type="text"
                         value="อายุ 29 ปี" disabled>
                </div>
              </div>
              <div class="form-group form-group-sm mb-0 row">
                <label class="col-sm-4 col-form-label">สัญชาติ</label>
                <div class="col-sm-8">
                  <input class="form-control form-control-sm"
                         :value="form.patient_type === '1' ? patient.nationality : form.nationality"
                         type="text" disabled>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group form-group-sm mb-0 row">
                <label class="col-sm-4 col-form-label">หมู่เลือด</label>
                <div class="col-sm-4">
                  <input class="form-control form-control-sm"
                         type="text"
                         :value="form.patient_type === '1' ? patient.bloodGroup.name : (bloodGroups.find(b => b.id == form.blood_group_id) ? bloodGroups.find(b => b.id == form.blood_group_id).name : '')" disabled>
                </div>
              </div>
              <div class="form-group form-group-sm mb-2 row">
                <label class="col-sm-4 col-form-label">โรคประจำตัว</label>
                <div class="col-sm-8">
                  <textarea class="form-control form-control-sm"
                            v-if="form.patient_type === '1'"
                            v-model="patient.congenital_disease" disabled></textarea>

                  <textarea class="form-control form-control-sm"
                            v-else
                            v-model="form.patient.congenital_disease" disabled></textarea>
                </div>
              </div>
              <div class="form-group form-group-sm mb-2 row">
                <label class="col-sm-4 col-form-label">การแพ้ยา</label>
                <div class="col-sm-8">
                  <textarea class="form-control form-control-sm"
                            v-if="form.patient_type === '1'"
                            v-model="patient.drug_allergy" disabled></textarea>

                  <textarea class="form-control form-control-sm"
                            v-else
                            v-model="form.patient.drug_allergy" disabled></textarea>
                </div>
              </div>
              <div class="form-group form-group-sm mb-0 row">
                <label class="col-sm-4 col-form-label">หมายเหตุ</label>
                <div class="col-sm-8">
                  <textarea class="form-control form-control-sm"
                            v-if="form.patient_type === '1'"
                            v-model="patient.note" disabled></textarea>

                  <textarea class="form-control form-control-sm"
                            v-else
                            v-model="form.patient.note" disabled></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="box mt-3">
    <div class="box-header small-box-header">
      <div class="row">
        <div class="col-md-6">
          <h4 class="box-title mb-0">ข้อมูลบริการ</h4>
        </div>
        <div class="col-md-6 text-end">
          <button class="btn btn-primary btn-sm">+ เพิ่มบริการ</button>
        </div>
      </div>
    </div>
    <div class="box-body p-3">
      <div class="myadmin-dd dd" id="nestable222">
        <ol class="dd-list">
          <li class="dd-item">
            <div class="dd-handle"
                 @click="$refs.serviceDetailsModal.show()"> Visit No: VN6500168 </div>
            <ol class="dd-list">
              <li class="dd-item">
                <div class="dd-handle"> Case: 00000011  |  วันที่นัดหมาย 28/08/2565  10:00-11:00 </div>
                <ol class="dd-list">
                  <li class="dd-item">
                    <div class="dd-handle"> Service No.: 00000001	- ศัลยกรรมจมูก </div>
                  </li>
                </ol>
              </li>
              <li class="dd-item">
                <div class="dd-handle"> Case: 00000001  |  วันที่นัดหมาย 28/08/2565  13:30 - 15:30 </div>
                <ol class="dd-list">
                  <li class="dd-item">
                    <div class="dd-handle"> Service No.: 00000001	- [Fatburn - NeoBella] 8 cc (ยกขวด) </div>
                  </li>
                  <li class="dd-item">
                    <div class="dd-handle"> Service No.: 00000001	- [Botox]ฉีดโบท็อกลดกรามหน้าเรียว </div>
                  </li>
                  <li class="dd-item">
                    <div class="dd-handle"> Service No.: 00000001	- [Treatment]โบท็อกซ์เกาหลี 10 cc[B]	 </div>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors'],
  data() {
    return {
      patient: null,
      bloodGroups: [],
      form: {},
    }
  },
  beforeMount() {
    this.form = this.formData;
    this.loadPatientDetails();
  },
  beforeUpdate() {
    this.form = this.formData;
    this.loadPatientDetails();
  },
  created() {
    this.loadBloodGroups();
  },
  mounted() {
    window.$('#nestable222').nestable({
      handleClass: '123'
    })
  },
  methods: {
    loadPatientDetails() {
      if ((this.patient && this.form.patient_type === '1' && this.patient.id != this.form.selected_patient_id) ||
        (!this.patient && this.form.patient_type === '1' && this.form.selected_patient_id)) {
        this.axios.get('/patients/' + this.form.selected_patient_id)
          .then(res => {
            this.patient = res.data.data;
          })
      }
    },
    loadBloodGroups() {
      this.bloodGroups = [];

      this.axios.get('/blood-groups')
        .then(res => {
          this.bloodGroups = res.data.data;
        });
    },
  }
}
</script>