<template>
  <div class="box-body p-3 h-500  d-flex align-items-center">
    <div class="text-center w-p100">
      <i class="fas fa-calendar fs-100 text-muted"></i>
      <h4 class="mt-3">No visit on this date</h4>
      <button class="btn btn-sm btn-primary" @click="nextStep">+ Add New</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData'],
  data() {
    return {
      form: {},
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    nextStep() {
      this.form.visit_id = '0';
      this.$emit('nextStep')
    }
  }
}
</script>