<template>
  <div class="row">
    <div class="col-4 h-500">
      <div class="box h-p100">
        <div class="box-header small-box-header">
          <h4 class="box-title">Basic Detail</h4>
        </div>
        <div class="box-body p-3" v-if="form.doctor">
          <div class="row">
            <div class="col-5">
              <img :src="form.doctor.image ? form.doctor.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="w-p100">
            </div>
            <div class="col-7">
              <span style="color:#5156BE;">◆</span>
              <span>&nbsp;{{ form.doctor.full_name }} <br> &nbsp;&nbsp;&nbsp;&nbsp;({{ form.doctor.department.name }})</span> <br>

              <span style="color:#5156BE;">◆</span>
              <span>&nbsp; {{ form.doctor.phone }}</span> <br>

              <span style="color:#5156BE;">◆</span>
              <span>&nbsp; {{ form.doctor.email }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-8 h-500">
      <div class="d-flex justify-content-center" v-if="loadingEvents">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div id="doctor-slot-calendar"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData', 'mainForm'],
  data() {
    return {
      loadingEvents: false,
      slots: [],
      events: [],
      form: {},
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  mounted() {
    setTimeout(() => {
      this.loadSlots()
    }, 100);
  },
  methods: {
    loadSlots() {
      this.events = [];
      this.slots = [];
      this.loadingEvents = true;

      this.axios.get('/doctor-slots', {
          params: {
            date: this.form.date,
            doctor_id: this.form.doctor.id,
            status: 0
          }
        })
        .then(res => {
          this.slots = res.data.data;

          this.slots = this.slots.filter((s) => {
            let found = false;

            if (!this.form.slot || (this.form.slot && this.form.slot.id !== s.id)) {
              this.mainForm.cases.forEach(c => {
                if (c.slot.id == s.id)
                  found = true;
              })
            }

            return !found;
          })

          this.events = this.slots.map(e => {
            let event = {
              id: e.id,
              start: e.start_time,
              end: e.end_time,
            }

            if (this.form.slot && this.form.slot.id == e.id) {
              event.title = 'เลือกแล้ว';
              event.className = 'bg-info';
            } else {
              event.title = 'ว่าง';
              event.className = 'bg-secondary';
            }

            return event;
          })

          setTimeout(() => {
            this.initCalender();
          }, 100)
        })
        .finally(() => this.loadingEvents = false)
    },
    initCalender() {
      let self = this;

      let calendarEl = document.getElementById('doctor-slot-calendar');
      let calendar = new window.FullCalendar.Calendar(calendarEl, {
        initialView: 'dayGridWeek',
        dayMaxEvents: true,
        selectable: true,
        initialDate: self.form.date,
        height: '100%',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,timeGridDay,listWeek'
        },
        themeSystem: 'bootstrap5',
        events: self.events,
        eventClick: function(info) {
          self.form.slot = self.slots.find(s => s.id == info.event.id);
          self.$emit('nextStep')
        },
      });
      calendar.render();
    }
  }
}
</script>