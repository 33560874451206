<template>
  <div class="box-body p-3 h-500" style="max-height: 500px; overflow: scroll">
    <div class="box" v-for="(c, i) in form.cases" :key="'case_'+i">
      <div class="box-header bg-primary-light small-box-header">
        <div class="row">
          <div class="col-6">
            <h4 class="box-title mb-0">Case {{ i+1 }}</h4>
          </div>
          <div class="col-6 text-end">
            <button class="btn btn-sm btn-primary"
                    @click="$refs.addCaseModal.show(c, true, i+1)">
              <i class="fas fa-clipboard"></i> จัดเคส
            </button>
          </div>
        </div>
      </div>
      <div class="box-body p-3">
        <div class="row">
          <div class="col-1">
            <img :src="c.doctor.image ? c.doctor.image : '/images/avatar-girl.png'"
                 onerror="this.src='/images/avatar-girl.png'"
                 class="w-p100">
          </div>
          <div class="col-11">
            <span style="color: #5156BE">◆</span> Doctor : {{ c.doctor.full_name }} <br>
            <span style="color: #5156BE">◆</span> Slot : {{ c.slot.date_format }} <br>
            <span style="color: #5156BE">◆</span> Service : {{ c.services.map(s => s.name_th).join(', ') }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <add-case-modal ref="addCaseModal"
                  :formData="form"
                  :id="3"
                  @addNewCase="addNewCase" />
</template>

<script>
import AddCaseModal from "@/views/appointment/newVisitModal/steps/3/AddCaseModal";
export default {
  components: {AddCaseModal},
  props: ['formData'],
  data() {
    return {
      form: {},
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    addNewCase(newCase) {
      if ('id' in newCase) {
        let i = this.form.cases.findIndex(c => c.id == newCase.id);

        if (i === -1)
          this.form.cases.push(newCase)
        else
          this.form.cases[i] = newCase
      } else {
        this.form.cases.push(newCase)
      }
    },
  }
}
</script>