<template>
  <div class="modal fade bs-example-modal-lg"
       id="consent-details-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true"
       style="display: none;">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            View Consent : Nasul Surgery Consent.PDF <br>
            <small class="text-muted">วันที่ : 01/08/2565  |  เวลา : 09:00 - 10:30  |  ผู้ให้บริการ : Unassigned</small>
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <pdf v-if="type === 'pdf'" src="/sample.pdf" />
          <video ref="videoPlayer" class="video-js" style="width: 100%; max-height: 435px"></video>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-danger">ปิด</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import pdf from 'pdfvuer'
import videojs from 'video.js';

export default {
  components: {pdf},
  data() {
    return {
      player: null,
      modal: null,
      type: 'pdf',
      videoOptions: {
        autoplay: false,
        controls: true,
        muted: false,
        sources: [
          {
            src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
            type: 'video/mp4'
          }
        ]
      }
    }
  },
  mounted() {
    let self = this;

    this.modal = new window.bootstrap.Modal(document.getElementById('consent-details-modal'));

    document.getElementById('consent-details-modal').addEventListener('hide.bs.modal', function () {
      if (self.type === 'video' && self.player) {
        self.player.dispose();
      }
    })
  },
  methods: {
    show(type) {
      this.type = type;

      if(type === 'video') {
        this.player = videojs(this.$refs.videoPlayer, this.videoOptions);
      }

      this.modal.show();
    }
  }
}
</script>