<template>
  <div class="box">
    <div class="box-body px-0 pb-0">
      <div class="px-20 bb-1 pb-15 d-flex align-items-center justify-content-between">
        <h4 class="mb-0">ประวัติรายการสั่งยาและผลิตภัณฑ์</h4>
      </div>
    </div>
    <div class="box-body">
      <table class="table table-small">
        <thead>
        <tr>
          <th>Case No.</th>
          <th>วันที่นัดหมาย</th>
          <th>ชื่อรายการ</th>
          <th class="text-end">ราคา</th>
          <th class="text-end">จำนวน</th>
          <th>หน่วยนับ</th>
          <th class="text-end">ราคารวม</th>
        </tr>
        </thead>

        <tbody>
        <template v-for="prescription in prescriptions"
                  :key="'prescription_'+prescription.id">
          <tr>
            <td :rowspan="prescription.products.length">{{ prescription.visit_case.case_no }}</td>
            <td :rowspan="prescription.products.length">{{ prescription.visit_case.date }}</td>
            <td>{{ prescription.products[0].product.name_sale }}</td>
            <td class="text-end">{{ prescription.products[0].unit_price  }}</td>
            <td class="text-end">{{ prescription.products[0].quantity }}</td>
            <td>{{ prescription.products[0].product.productUnit ? prescription.products[0].product.productUnit.name_th : '' }}</td>
            <td class="text-end">{{ prescription.products[0].total }}</td>
          </tr>

          <template v-for="(product, i) in prescription.products"
                    :key="'product_'+product.id">
            <tr v-if="i !== 0">
              <td>{{ product.product.name_sale }}</td>
              <td class="text-end">{{ product.unit_price }}</td>
              <td class="text-end">{{ product.quantity }}</td>
              <td>{{ product.product.productUnit ? product.product.productUnit.name_th : '' }}</td>
              <td class="text-end">{{ product.total }}</td>
            </tr>
          </template>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      prescriptions: []
    }
  },
  created() {
    this.loadPrescriptions();
  },
  methods: {
    loadPrescriptions() {
      this.prescriptions = [];

      this.axios.get('/prescriptions?visit_id=' + this.$route.params.id)
        .then(res => {
          this.prescriptions = res.data.data;
        })
    },
  }
}
</script>