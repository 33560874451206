<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('appointments.appointments') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('appointments.appointments') }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-body">
          <div class="row">
            <div class="col-md-7">
              <div class="input-group mb-3">
                <input type="text"
                       @keyup="searchText($event.target.value)"
                       class="form-control"
                       placeholder="ค้นหาด้วยการระบุ Visit No. หรือ HN หรือ ชื่อ นามสกุล">
                <span class="input-group-text"><i class="ti-search"></i></span>
              </div>
            </div>
            <div class="col-md-2">
              <select class="form-select"
                      @change="refreshData"
                      v-model="queryParams.doctor_id">
                <option value="">Doctor: All Doctor</option>
                <option :value="doctor.id"
                        v-for="doctor in doctors"
                        :key="'doctor_'+doctor.id">{{ doctor.full_name }}</option>
              </select>
            </div>
            <div class="col-md-2">
              <select class="form-select"
                      @change="refreshData"
                      v-model="queryParams.status">
                <option value="">Status: All Status</option>
                <option value="1">Draft</option>
                <option value="2">Open</option>
                <option value="3">Ongoing</option>
                <option value="4">Finished</option>
                <option value="5">Cancel</option>
              </select>
            </div>
            <div class="col-md-1">
              <div class="d-grid">
                <button type="button"
                        @click="$refs.newVisitModal.show()"
                        class="waves-effect waves-light btn btn-primary btn-sm">+ Add New</button>
              </div>
            </div>
          </div>

          <table-component :columns="columns"
                           :rows="visits"
                           :sortable="sortable"
                           @sorting="sorting"
                           :search="false"
                           :pagination="pagination"
                           @search="search"
                           @pageChanged="pageChanged">

            <template v-slot:booking_no="{ row }">
              <router-link :to="{name: 'visit_details_cases', params: {id: row.booking_id}}">
                {{ row.booking_no }}
              </router-link>
            </template>

            <template v-slot:status="{ row }">
              <appointment-status :status="row.status" />
            </template>

            <template v-slot:doctors="{ row }">
              <img :src="c.doctor.image ? c.doctor.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="h-30 w-30 rounded me-2"
                   v-for="c in row.cases"
                   :key="'case_'+c.id">
            </template>

            <template v-slot:action="{ row }">
              <router-link class="text-dark"
                           :to="{name: 'visit_details_cases', params: {id: row.id}}">
                <i class="fas fa-user"></i> View
              </router-link>
              <template v-if="row.status !== 5">
                |
                <a class="text-dark"
                   @click.prevent="cancelVisit(row)"
                   href="#">
                  <i class="far fa-times-circle"></i> Cancel
                </a>
              </template>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>

  <new-visit-modal ref="newVisitModal" @added="loadData" />
</template>

<script>
import TableComponent from "@/components/TableComponent";
import NewVisitModal from "./newVisitModal/NewVisitModal";
import AppointmentStatus from "@/views/appointment/AppointmentStatus.vue";
export default {
  components: {TableComponent, NewVisitModal, AppointmentStatus},
  data() {
    return {
      visits: [],
      doctors: [],
      pagination: null,
      queryParams: {
        status: '',
        doctor_id: '',
        page: 1,
        search: '',
        sort: 'visit_no',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'visit_no',
        sort: 'desc'
      },
      columns: [
        {
          label: "Visit No.",
          field: "visit_no",
          sortable: true,
          searchable: true,
        },
        {
          label: "HN",
          field: "patient.hn",
          sortable: true,
          searchable: true,
        },
        {
          label: "Name",
          field: "patient.full_name",
          sortable: false,
          searchable: true,
        },
        {
          label: "Doctor(s)",
          field: "doctors",
          sortable: false,
          searchable: false,
          slot: true
        },
        {
          label: "Date",
          field: 'date',
          sortable: true,
          searchable: false,
        },
        {
          label: "Time",
          field: 'time',
          sortable: false,
          searchable: false
        },
        {
          label: "No. of Cases",
          field: 'case',
          sortable: false,
          searchable: false,
          display(row) {
            return row.cases.length;
          }
        },
        {
          label: "Status",
          field: 'status',
          sortable: true,
          searchable: false,
          slot: true
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          searchable: false,
          slot: true,
          class: ['text-end']
        },
      ],
    }
  },
  created() {
    this.loadData();
    this.loadDoctors();
  },
  methods: {
    loadDoctors() {
      this.doctors = [];

      this.axios.get('/doctors')
        .then(res => {
          this.doctors = res.data.data;
        })
    },
    loadData() {
      this.visits = [];

      this.axios.get('/visits', {params: this.queryParams})
        .then((response) => {
          this.visits = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    cancelVisit(row) {
      this.$swal({
        title: 'Are you sure want to cancel?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, cancel it!',
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/visits/change-status', {
              visit_id: row.id,
              status: 5
            })
            .then(() => {
              row.status = 5;
            })
        }
      });
    }
  }
}
</script>