<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">Appointment</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">Request</li>
            </ol>
          </nav>
        </div>
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-body">
          <table-component :columns="columns"
                           :rows="appointments"
                           :sortable="sortable"
                           @sorting="sorting"
                           :pagination="pagination"
                           @search="search"
                           @pageChanged="pageChanged">

            <template v-slot:booking_no="{ row }">
              <router-link :to="{name: 'visit_details_cases', params: {id: row.booking_id}}">
                {{ row.booking_no }}
              </router-link>
            </template>

            <template v-slot:top>
              <div class="row">
                <div class="col-md-4">
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </div>
                    <input type="text" class="form-control pull-right" id="daterange">
                  </div>
                </div>
              </div>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent";
export default {
  components: {TableComponent},
  data() {
    return {
      appointments: [],
      pagination: null,
      sortable: {
        order: 'booking_no',
        sort: 'asc'
      },
      queryParams: {
        booking_status: [1,2,3,4,5,6],
        booking_service_status: [7,8,9,10],
        start: window.moment().subtract(29, "days").format('YYYY-MM-DD'),
        end: window.moment().format('YYYY-MM-DD'),
      },
      columns: [
        {
          label: "Request No.",
          field: "booking_no",
          sortable: false,
          searchable: false,
          slot: true,
        },
        {
          label: "Patient Name",
          field: "customer_hn",
          sortable: false,
          searchable: false,
          display(row) {
            return row.customer_info ? (row.customer_info.first_name + ' ' + row.customer_info.last_name) : '';
          }
        },
        {
          label: "HN",
          field: "customer_info.hn",
          sortable: false,
          searchable: false,
          display(row) {
            return row.customer_info ? row.customer_info.hn : '';
          }
        },
        {
          label: "Doctor",
          field: "hn",
          sortable: true,
          searchable: true,
        },
        {
          label: "Date",
          field: 'booking_date',
          sortable: true,
          searchable: false,
        },
        {
          label: "Time",
          field: 'time',
          sortable: false,
          searchable: true,
          display(row) {
            return row.service_start_time + ' - ' + row.service_end_time
          }
        },
        {
          label: "Services",
          field: 'service_name',
          sortable: false,
          searchable: false,
        },
        {
          label: "Status",
          field: 'status_name',
          sortable: false,
          searchable: false,
        }
      ],
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    let t = window.moment().subtract(29, "days");
    let a = window.moment();
    let self = this;

    window.$("#daterange").daterangepicker({
        startDate: t,
        endDate: a,
        opens: 'right',
        locale: {
          format: 'MMM D, YYYY'
        },
      },
      function (t, a) {
        self.queryParams.start = t.format('YYYY-MM-DD');
        self.queryParams.end = a.format('YYYY-MM-DD');
        self.loadData();
      }
    )
  },
  methods: {
    loadData() {
      this.appointments = [];

      this.axios.post('/booking/workscheduleevent', this.queryParams)
        .then((response) => {
          if (response.data.status) {
            this.appointments = response.data.results.booking_list;
            // this.pagination = {
            //   per_page: this.queryParams.limit,
            //   total: response.data.results.booking_list.length
            // }
          } else {
            this.pagination = null;
          }
        })
    },
  }
}
</script>