<template>
  <div class="box">
    <div class="box-body">
      <table class="table">
        <thead>
        <tr>
          <th>Case</th>
          <th>Room</th>
          <th>เคาเตอร์พยาบาล</th>
          <th>Status</th>
          <th class="text-end">View</th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="c in cases"
            :key="'case_'+c.id">
          <td>
            <div class="d-flex align-items-center">
              <div class="me-15">
                <img :src="c.doctor.image ? c.doctor.image : '/images/avatar-girl.png'"
                     onerror="this.src='/images/avatar-girl.png'"
                     class="avatar avatar-lg rounded10" />
              </div>
              <div class="d-flex flex-column flex-grow-1 fw-500">
                <a href="#" class="text-dark hover-primary mb-1 fs-16">{{ c.doctor.full_name }}</a>
                <span class="text-fade">{{ c.services.map(s => s.name_th).join(', ') }}</span>
              </div>
            </div>
          </td>
          <td></td>
          <td></td>
          <td>
            <case-status :status="c.status" />
          </td>
          <td class="text-end">
            <router-link :to="{name: 'case_notes', params: {id: c.id}}"
                         class="btn btn-info-light">
              <i class="fas fa-arrow-right"></i>
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import CaseStatus from "@/views/case/CaseStatus.vue";
export default {
  components: {CaseStatus},
  data() {
    return {
      cases: []
    }
  },
  created() {
    this.loadCases();
  },
  methods: {
    loadCases() {
      this.cases = [];

      this.axios.get('/visit-cases?visit_id=' + this.$route.params.id)
        .then(res => {
          this.cases = res.data.data;
        })
    }
  }
}
</script>