<template>
  <div class="modal fade bs-example-modal-lg"
       :id="'add-new-case-modal-'+id"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel2"
       aria-hidden="true">
    <div class="modal-dialog modal-lg modal-xl modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel2">จัดเคส : Case {{ caseNo }}</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-2 sticky-top h-100">
              <div class="d-grid gap-2">
                <button :class="'btn btn-sm ' + (currentStep >= 1 ? 'btn-primary' : 'btn-secondary')">1. Doctor</button>
                <button :class="'btn btn-sm ' + (currentStep >= 2 ? 'btn-primary' : 'btn-secondary')">2. Slot</button>
                <button :class="'btn btn-sm ' + (currentStep >= 3 ? 'btn-primary' : 'btn-secondary')">3. Service</button>
              </div>
            </div>
            <div class="col-10">
              <component :formData="form"
                         :mainForm="formData"
                         @nextStep="nextStep"
                         :is="'Step'+currentStep" />
            </div>
          </div>
        </div>
        <div class="modal-footer p-2">
          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary btn-sm"
                      @click="hide">ยกเลิกการสร้าง</button>
            </div>
            <div class="col-md-6 text-end">
              <button type="button"
                      @click="currentStep -= 1"
                      :disabled="currentStep === 1"
                      :class="['btn me-3 btn-sm', {'btn-info-light': currentStep === 1}, {'btn-primary': currentStep !== 1}]">
                <i class="fas fa-arrow-left"></i> ย้อนกลับ
              </button>
              <button type="button"
                      @click="nextStep"
                      v-if="currentStep !== 3"
                      :disabled="!canGoNext"
                      :class="['btn btn-sm', {'btn-info-light': currentStep === 5}, {'btn-primary': currentStep !== 5}]">
                ถัดไป <i class="fas fa-arrow-right"></i>
              </button>
              <button type="button"
                      v-else
                      @click="submit"
                      :disabled="!canGoNext || loading"
                      class="btn btn-primary btn-sm">
                บันทึกเคส
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import Step1 from './add_case_steps/Step1';
import Step2 from './add_case_steps/Step2';
import Step3 from './add_case_steps/Step3';
import useVuelidate from "@vuelidate/core";
import {required, minLength} from "@vuelidate/validators";
export default {
  props: ['id', 'formData'],
  components: {Step1, Step2, Step3},
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    if (this.currentStep === 1) {
      return {
        form: {
          doctor: {required},
        },
      }
    } else if (this.currentStep === 2) {
      return {
        form: {
          date: {required},
          slot: {required},
        },
      }
    } else if (this.currentStep === 3) {
      return {
        form: {
          services: { required, minLength: minLength(1), },
        },
      }
    }
  },
  data() {
    return {
      modal: null,
      caseNo: 1,
      currentStep: 1,
      form: {
        doctor: null,
        slot: null,
        services: [],
        date: ''
      }
    }
  },
  computed: {
    canGoNext() {
      if (this.currentStep === 1) {
        return !this.v$.form.$invalid
      } else if (this.currentStep === 2) {
        return !this.v$.form.$invalid
      } else if (this.currentStep === 3) {
        return !this.v$.form.$invalid
      } else
        return true;
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-new-case-modal-' + this.id));
  },
  methods: {
    reset() {
      this.currentStep = 1;
      this.form = {
        doctor: null,
        slot: null,
        services: [],
        date: ''
      }
    },
    submit() {
      if (!('id' in this.form)) {
        this.form.id = 'new_' + Math.floor(Math.random() * 9999999999999);
      }

      this.$emit('addNewCase', this.form);
      this.hide();
    },
    show(data, edit = false, caseNo) {
      this.modal.show();
      this.caseNo = caseNo;
      this.reset();

      if (edit) {
        this.form = data
      } else {
        this.form.date = data;
      }
    },
    hide() {
      this.modal.hide();
      // this.modal.dispose();
    },
    nextStep() {
      this.currentStep++;
    },
  }
}
</script>