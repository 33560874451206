<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('appointments.appointments') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('appointments.calender') }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-body p-3 h-700">
          <div id="calendar"></div>
        </div>
      </div>
    </div>
  </div>

  <calender-sidebar :date="selectedDate"
                    :appointments="sideBarAppointments" />

  <a href="#"
     v-show="false"
     data-toggle="control-sidebar"
     title="Setting"
     id="sideBtn"
     class="btn btn-primary waves-effect btn-sm">View Request</a>
</template>

<script>
import CalenderSidebar from './CalenderSidebar'
export default {
  components: {CalenderSidebar},
  data() {
    return {
      sideBarAppointments: [],
      selectedDate: null,
      appointments: [],
      events: []
    }
  },
  mounted() {
    this.loadAppointment();
    this.initSidebar('appointment-calendar-side');
  },
  methods: {
    loadSidebarAppointment(date) {
      this.sideBarAppointments = this.appointments.filter(a => a.date === date);
    },
    loadAppointment() {
      this.axios.get('/visit-cases')
        .then(res => {
          this.appointments = res.data.data;
          this.events = res.data.data.map(e => {
            let event = {
              start: e.start_time,
              end: e.end_time,
            }

            event.title = e.patient ? e.patient.full_name : e.visit_id;
            event.className = 'bg-danger';

            return event;
          })

          setTimeout(() => {
            this.initCalender();
          }, 500)
        })
    },
    initCalender() {
      let self = this;

      let calendarEl = document.getElementById('calendar');
      let calendar = new window.FullCalendar.Calendar(calendarEl, {
        initialView: 'dayGridMonth',
        height: '100%',
        selectable: true,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,timeGridDay,listWeek'
        },
        themeSystem: 'bootstrap5',
        events: self.events,
        dateClick: function(info) {
          window.$('#sideBtn').trigger('click');
          self.selectedDate = info.dateStr;
          self.loadSidebarAppointment(info.dateStr);
        }
      });
      calendar.render();
    }
  }
}
</script>